
body {
    margin : 0;
}

.nav {
    display: flex;
    justify-content: right;
    padding-right: 2%;
    padding-bottom: 10%;
}

.nav li {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.4);
}

.nav ul {
    list-style: none;
}

.nav a {
    font-family: Manrope;
    text-decoration: none;
    color: black;
}

.nav a:hover {
    color: white;
}