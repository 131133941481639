p {
    color: black;
    font-family: Manrope;
}

.projectLogEntry {
    margin: 20px;
    background-color: white;
    opacity: 0.7;
    padding: 10px;
    border-radius: 10px;
}

h3 {
    font-family: Oswald;
}